import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import Container from "./Layout/Container"
import Banner from "./Banner"
import FBLikeButton from "./FBLikeButton"

import bg from "../images/background2.jpg"
import instagramIcon from "../images/instagram.svg"

const ContactSection = styled.section`
  background-color: ${props => props.theme.bg};
`

const RowContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  padding-top: 2.5rem;
  padding-bottom: 2rem;

  ${props => props.theme.mediumScreen} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }

  ${props => props.theme.smallScreen} {
    display: flex;
    flex-direction: column;
  }
`

const ContactInfo = styled.div`
  text-align: center;
  h5 {
    margin-bottom: 1rem;
    font-weight: 300;
    font-size: 1.75rem;
    line-height: 1.2;
    a {
      text-decoration: none;
      color: inherit;
      &:hover {
        color: ${props =>
          props.medium ? props.theme[props.medium] : "inherit"};
      }
    }
  }
  img {
    height: 30px;
  }
  ${props => props.theme.smallScreen} {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Contact = () => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsInfo {
        nodes {
          email
          facebookUrl
          instagramUrl
          phoneNumber
        }
      }
    }
  `)

  return (
    <ContactSection>
      <Banner image={bg} text="kontakt" />
      <RowContainer>
        <ContactInfo>
          <h5>Telefon</h5>
          <p>{data.allDatoCmsInfo.nodes[0].phoneNumber}</p>
        </ContactInfo>
        <ContactInfo>
          <h5>Email</h5>
          <p>{data.allDatoCmsInfo.nodes[0].email}</p>
        </ContactInfo>
        <ContactInfo medium="instagram">
          <h5>
            <a href={data.allDatoCmsInfo.nodes[0].instagramUrl}>Instagram</a>
          </h5>
          <a href={data.allDatoCmsInfo.nodes[0].instagramUrl}>
            <img src={instagramIcon} alt="instagram" />
          </a>
        </ContactInfo>
        <ContactInfo medium="facebook">
          <h5>
            <a href={data.allDatoCmsInfo.nodes[0].facebookUrl}>Facebook</a>
          </h5>
          <FBLikeButton />
        </ContactInfo>
      </RowContainer>
    </ContactSection>
  )
}

export default Contact
