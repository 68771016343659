import React from "react"
import styled from "styled-components"
import Container from "./Layout/Container"

const Footer1 = styled.section`
  background-color: ${props => props.theme.primary};
  text-align: center;
  padding: 2rem;
  font-size: 1.25rem;
  font-weight: 300;
  h5 {
    margin-bottom: 0.5rem;
  }
`

const Footer2 = styled.section`
  background-color: ${props => props.theme.secondary};
  text-align: center;
  padding: 1rem;
  font-size: 0.8rem;
  font-weight: 300;
  color: #fac7ff;
  a {
    color: ${props => props.theme.text};
    text-decoration: none;
  }
`

const RowContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
`

const Footer = () => (
  <footer>
    <Footer1>
      <h5>DJ HUBI</h5>
      <p>Człowiek orkiestra</p>
    </Footer1>
    <Footer2>
      <RowContainer>
        <span>
          Icons made by{" "}
          <a href="https://www.flaticon.com/authors/freepik" title="Freepik">
            Freepik
          </a>{" "}
          from{" "}
          <a href="https://www.flaticon.com/" title="Flaticon">
            www.flaticon.com
          </a>
        </span>
        <span>
          made by <a href="https://github.com/KrzysztofWelc">Krzysztof Welc</a>
        </span>
      </RowContainer>
    </Footer2>
  </footer>
)

export default Footer
