import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import Container from "./Layout/Container"
import Banner from "./Banner"
import Card from "./Card"

import bg from "../images/background3.jpg"

const OfferSection = styled.section`
  background-color: ${props => props.theme.bg};
`

const RowContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 2rem;

  ${props => props.theme.largeMediumScreen} {
    flex-direction: column;
  }
`

const Offer = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsOfert {
        nodes {
          title
          content
          order
          id
          image {
              fluid(maxWidth: 450, imgixParams: { fm: "jpg", auto: "compress" }) {
                  ...GatsbyDatoCmsFluid
              }
          }
        }
      }
    }
  `)

  return (
    <OfferSection ref={props.sectionRef}>
      <Banner image={bg} text="oferta" />
      <RowContainer>
        {data.allDatoCmsOfert.nodes.map(card => {
          return (
            <Card
              key={card.id}
              order={card.order}
              title={card.title}
              content={card.content}
              image={card.image}
            />
          )
        })}
      </RowContainer>
    </OfferSection>
  )
}

export default Offer
