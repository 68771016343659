import React from "react"
import styled from "styled-components"

const Center = styled.div`
  display: flex;
  justify-content: center;
`

const Like = styled.iframe`
  height: 30px;
  width: 100px;
  border: none;
  overflow: hidden;
  margin: auto auto 1rem;
`

const FBLikeButton = () => (
  <Center>
    <Like
      title='facebook like button'
      src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2Fkrkdjhubi%2F&width=69&layout=button&action=like&size=large&share=false&height=65&appId=2438013899586781"
      scrolling="no"
      frameborder="0"
      allowtransparency="true"
      allow="encrypted-media"
    />
  </Center>
)

export default FBLikeButton
