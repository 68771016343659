import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import bg1 from "../images/background1.jpg"

import Container from "./Layout/Container"

const StyledHeader = styled.header`
  background-image: url(${bg1});
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
`

const StyledGreeting = styled.div`
  text-align: center;
  h1 {
    font-weight: 600;
    font-size: 80px;
  }
  hr {
    border-top: 1px solid white;
    margin: 0.5rem 0 1rem 0;
  }
  p {
    font-size: 1.5rem;
    font-weight: 300;
  }
  button {
    cursor: pointer;
    background-color: transparent;
    padding: 0.76rem 2.13rem;
    border: 2px solid white;
    border-radius: 2px;
    margin-top: 1rem;
    color: inherit;
    font: inherit;
  }
`

const Header = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsInfo {
        nodes {
          buttonText
          pageTitle
          subtitle
        }
      }
    }
  `)

  const scrollHandler = () => {
    if(props.elementToScroll){
      props.elementToScroll.current.scrollIntoView({
        behavior: "smooth"
      })
    }
  }

  return (
    <StyledHeader>
      <Container>
        <StyledGreeting>
          <h1>{data.allDatoCmsInfo.nodes[0].pageTitle}</h1>
          <hr />
          <p>{data.allDatoCmsInfo.nodes[0].subtitle}</p>
          <button onClick={scrollHandler}>{data.allDatoCmsInfo.nodes[0].buttonText}</button>
        </StyledGreeting>
      </Container>
    </StyledHeader>
  )
}

export default Header
