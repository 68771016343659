import React, {useRef} from "react"
import { ThemeProvider } from "styled-components"

import theme from "../styles/theme"

import GlobalStyle from "../styles/GlobalStyles"

import SEO from "../components/SEO"
import Header from "../components/Header"
import HeroComponent from "../components/Hero"
import Offer from "../components/OfferSection"
import Contact from "../components/ContactSection"
import Gallery from "../components/Gallery"
import Footer from "../components/Footer"

const IndexPage = () => {
    const scrollToSection = useRef(null)

    return (
        <ThemeProvider theme={theme}>
            <SEO/>
            <GlobalStyle/>
            <Header elementToScroll={scrollToSection}/>
            <HeroComponent/>
            <Offer sectionRef={scrollToSection}/>
            <Contact/>
            <Gallery/>
            <Footer/>
        </ThemeProvider>
    )
}

export default IndexPage
