import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import Container from "./Layout/Container"

const Hero = styled.div`
  background-color: ${props => props.theme.bg};
  color: ${props => props.theme.text};
  padding: 2rem 0;
  font-weight: 300;
  line-height: 1.5;
  ${props => props.theme.mediumScreen} {
    text-align: center;
  }
`

const HeroComponent = () => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsInfo {
        nodes {
          welcomeMessage
        }
      }
    }
  `)

  return (
    <Hero>
      <Container>
        <p>{data.allDatoCmsInfo.nodes[0].welcomeMessage}</p>
      </Container>
    </Hero>
  )
}

export default HeroComponent
