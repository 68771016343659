import React from "react"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import Img from 'gatsby-image'

const CardComponent = styled.div`
  width: 32%;
  color: ${props => props.theme.secondaryText};
  background-color: ${props => props.theme.text};
  border-radius: 3px;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
  order: ${props => props.order};
  img{
    width: 100%;  
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  ${props => props.theme.largeMediumScreen}{
    width: 100%;
    margin-bottom: 1.5rem;
    img{
    height: 350px;
    object-fit: cover;
    }

    ${props => props.theme.largeMediumScreen}{ 
      img{
        height: 300px;
      }
    }
`

const CardBody = styled.div`
  padding: 1.25rem;
  h3 {
    font-weight: 400;
    margin-bottom: 0.75rem;
    line-height: 1.2;
    font-size: 1.5rem;
  }

  ul {
    margin-left: 1rem;
  }

  li {
    font-weight: 400;
    line-height: 1.6;
    font-size: 0.9rem;
  }

  p {
    font-weight: 400;
    line-height: 1.6;
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }
`

const Card = props => {
  return (
    <CardComponent order={props.order}>
      <Img fluid={props.image.fluid}/>
      <CardBody>
        <h3>{props.title}</h3>
        <ReactMarkdown source={props.content} />
      </CardBody>
    </CardComponent>
  )
}

export default Card
