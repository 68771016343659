import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Masonry from "react-masonry-css"
import "./Masonry.css"

import Container from "./Layout/Container"

const GallerySection = styled.section`
  background-color: ${props => props.theme.bg};
  h2 {
    //padding-top: 1.5rem;
    text-align: center;
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2;
    margin-bottom: 1.5rem;
  }
`

const Gallery = props => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsInfo {
        nodes {
          gallery {
            fluid(maxWidth: 450, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  `)

  return (
    <GallerySection>
      <h2>Galeria</h2>
      <Container>
        <Masonry
          breakpointCols={{
            default: 4,
            930: 3,
            770: 2,
            370: 1,
          }}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {data.allDatoCmsInfo.nodes[0].gallery.map((image, index) => {
            return <Img key={index} fluid={image.fluid} />
          })}
        </Masonry>
      </Container>
    </GallerySection>
  )
}

export default Gallery
