import React from "react"
import { Helmet } from "react-helmet"
import {useStaticQuery, graphql} from "gatsby"

const SiteMetadata = () => {

  const data = useStaticQuery(graphql`
    query {
        allDatoCmsSeo {
            nodes {
                facebookImage {
                    url
                }
                keywords
                description
                domain
            }
        }
        allDatoCmsInfo {
            nodes {
                pageTitle
            }
        }
    }

  `)
  
  return (
    <Helmet defer={false} title={data.allDatoCmsInfo.nodes[0].pageTitle} defaultTitle={data.allDatoCmsInfo.nodes.pageTitle}>
      <html lang='pl'/>
      <meta name='description' content={data.allDatoCmsSeo.nodes[0].description}/>
      <meta name='keywords' content={data.allDatoCmsSeo.nodes[0].keywords}/>
      <meta name='author' content='Krzysztof Welc'/>

      <meta property='og:image' content={data.allDatoCmsSeo.nodes[0].facebookImage.url}/>
      <meta property='og:title' content={data.allDatoCmsInfo.nodes[0].pageTitle}/>
      <meta property='og:url' content={data.allDatoCmsSeo.nodes[0].domain}/>
      <meta property='og:description' content={data.allDatoCmsSeo.nodes[0].description}/>
      <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap" rel="stylesheet"/>
    </Helmet>
  )
}

export default SiteMetadata