import React from "react"
import styled from "styled-components"

const BannerContainer = styled.div`
  min-height: 400px;
  background-attachment: fixed;
  text-shadow: 5px 5px 8px black;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${props => props.image});

  h2 {
    font-weight: 700;
    font-size: 4.5rem;
    color: ${props => props.theme.primary};
  }
`

const Banner = props => {
  return (
    <BannerContainer image={props.image}>
      <h2>{props.text}</h2>
    </BannerContainer>
  )
}
export default Banner
